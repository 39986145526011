import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    OtpData: null,
}

const slice = createSlice({
    name: 'RequestOTP',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.OtpData = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        requestOTP(state, action) {
            state.loading = false;
            state.error = null;
            state.OtpData = action.payload;
        },
        resetState(state) {
            state.loading = false;
            state.OtpData = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { requestOTP, resetState } = slice.actions;

export function RequestMobileOTP(token, mobile, iso2) {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${DomainURL.BaseExternalURL}verify/phone?country=${iso2}&phone=${mobile}`, null, { headers: headerParams(token), });
            dispatch(slice.actions.requestOTP(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error.data));
        }
    };
}

export function resetRequestOTPState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
    }
}