import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    exData: null,
}

const slice = createSlice({
    name: 'experienceIndividual',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.exData = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getIndiExpe(state, action) {
            state.loading = false;
            state.error = null;
            state.exData = action.payload;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.exData = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getIndiExpe, } = slice.actions;

export function GetIndividualExperience(token, userId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}user/work-history/${userId}`, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.getIndiExpe(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetExperience() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}