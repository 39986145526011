import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    contactReqList: null,
    totalpage: null,
}

const slice = createSlice({
    name: 'jsContactReqList',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.contactReqList = null;
            state.totalpage = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getcontactReqList(state, action) {
            state.loading = false;
            state.error = null;
            state.contactReqList = action.payload?.data;
            state.totalpage = action.payload?.metadata?.TotalRecordCount;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.contactReqList = null;
            state.totalpage = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getcontactReqList } = slice.actions;

export function GetContactReqList(token, status, page) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}contact/filter?status=${status}&pageNumber=${page}&pageSize=10`, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.getcontactReqList(response?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
