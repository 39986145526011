import { createSlice } from '@reduxjs/toolkit';
// header
import { headerParams } from 'src/utils/headers';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    favData: null,
}

const slice = createSlice({
    name: 'InternalJobsFavDetails',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.message = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        favouriteJob(state, action) {
            state.loading = false;
            state.favData = action.payload
        },
        resetStateFetch(state) {
            state.loading = false;
            state.favData = null;
            state.error = null;
        },
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { favouriteJob } = slice.actions;

export function internalJobsFavouriteDetails(token, jobId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}candidate/favourite/job?jobId=${jobId}`, { headers: headerParams(token) });
            dispatch(slice.actions.favouriteJob(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetFavourite() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}