import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    token: null,
    error: null
}

const slice = createSlice({
    name: 'authenticate',
    initialState,
    reducers: {
        authTokenFetchSuccess(state, action) {
            state.token = action.payload.data;
        },
        hasError(state, action) {
            state.token = null;
            state.error = action.payload;
        },
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { authTokenFetchSuccess } = slice.actions;

export function GetToken(token) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.authTokenFetchSuccess({ data: token }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}