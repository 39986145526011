import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    isoList: null,
}

const slice = createSlice({
    name: 'isoCode',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.isoList = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getIsoSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.isoList = action.payload;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.isoList = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getIsoSuccess } = slice.actions;

export function GetIso() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}lookup/country-iso3/codes`);
            dispatch(slice.actions.getIsoSuccess(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
