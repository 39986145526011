import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    message: null,
}

const slice = createSlice({
    name: 'experienceDelete',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.message = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        delteExperience(state, action) {
            state.loading = false;
            state.error = null;
            state.message = action.payload;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.message = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { delteExperience, } = slice.actions;

export function DeleteExperience(token, data) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`${DomainURL.BaseUrl}user/candidate/work-history`, data, { headers: headerParams(token) });
            dispatch(slice.actions.delteExperience(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error.data));
        }
    };
}


export function resetDeleteExperience() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}