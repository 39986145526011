import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const MiniLogo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const HEXGONAL_TEXT_COLOR = theme.palette.mode === 'light' ? '#FFFFFF' : '#231f20'
  const HEXGONAL_FILL_COLOR = theme.palette.mode === 'light' ? '#231f20' : "#FFFFFF"

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 140,
        height: 120,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg id="Layer_2"
        xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 104.9 121.13">
        <g id="Layer_1-2">
          <polygon points="102.9 89.69 52.45 118.82 2 89.69 2 31.44 52.45 2.31 102.9 31.44 102.9 89.69" fill={HEXGONAL_FILL_COLOR} stroke={HEXGONAL_FILL_COLOR} strokeMiterlimit="10" strokeWidth="4" />
          <polygon points="38.43 91.96 30.12 91.96 14.12 29.17 22.73 29.17 38.43 91.96" fill={HEXGONAL_TEXT_COLOR} />
          <polygon points="73.02 91.96 64.63 91.96 48.52 29.17 57 29.17 73.02 91.96" fill={HEXGONAL_TEXT_COLOR} />
          <polygon points="73.37 91.96 66.13 91.96 82.49 29.17 90.79 29.17 73.37 91.96" fill={HEXGONAL_TEXT_COLOR} />
          <polygon points="38.7 91.96 31.47 91.96 47.83 29.17 56.12 29.17 38.7 91.96" fill={HEXGONAL_TEXT_COLOR} />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

MiniLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default MiniLogo;
