import CryptoJS from "crypto-js";

export function headerParams(token) {
    // let headers = null;
    const headers = {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    };
    return headers
}



const dateString = new Date().toUTCString()

export function headerParamsSec(path, method) {
    // get current date in gmt format
    const payload = generatePayload(path, method, dateString)
    const signature = HmacSHA256(payload);

    const headers = {
        Authorization: `HMAC-SHA256 Credential=${process.env.REACT_APP_HMAC_CLIENTID},SignedHeaders=X-Request-Date, Signature=${signature}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "X-Request-Date": dateString,
    };
    return { headers }
}


function generatePayload(path, method) {
    const requestLine = `${method} ${path} HTTP/1.1`;
    // Create an array with the date and request line
    const combinedArray = [`x-request-date: ${dateString}`, requestLine];
    // Join the array elements using a newline separator
    const resultString = combinedArray.join("\n");
    return resultString
}

function HmacSHA256(payload) {
    try {
        // Convert the client secret to a WordArray
        const signingKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_HMAC_CLIENTSECRET);

        // Compute the HMAC using SHA-256
        const hmacDigest = CryptoJS.HmacSHA256(payload, signingKey);

        // Convert the HMAC digest to a base64-encoded string
        const base64Digest = CryptoJS.enc.Base64.stringify(hmacDigest);

        return base64Digest;
    } catch (error) {
        console.error('Error calculating HMAC:', error);
        return null;
    }
}
