import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    Loading: true,
    error: null,
    patch: null,
}

const slice = createSlice({
    name: 'patchInapp',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.Loading = true;
            state.patch = null;
        },
        hasError(state, action) {
            state.Loading = false;
            state.error = action.payload.data;
        },
        patchInapp(state, action) {
            state.Loading = false;
            state.error = null;
            state.patch = action.payload;
        },
        resetStatePatchInapp(state) {
            state.Loading = false;
            state.patch = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { patchInapp } = slice.actions;

export function PatchInapp(token, callBackUrl) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStatePatchInapp());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`${DomainURL.BaseUrl}${callBackUrl}`, {}, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.patchInapp(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
