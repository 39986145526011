import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    message: null,
}

const slice = createSlice({
    name: 'jsContactReqResponce',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.contactReqList = null;
            state.totalpage = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        responseContactReq(state, action) {
            state.loading = false;
            state.error = null;
            state.message = action.payload?.data;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.message = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { responseContactReq } = slice.actions;

export function ResponseContactReq(token, data) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${DomainURL.BaseUrl}contact/candidate-response/`, data, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.responseContactReq(response?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetResponseContactReq() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}