import PropTypes from 'prop-types';
import { useEffect } from 'react';
// redux-store
import { useSelector } from 'src/redux/store';
import { useRouter, usePathname } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export default function OnboardingGuard({ roles, children }) {
    const { reqCollection } = useSelector(state => state.profileStatus)
    const { userPreview } = useSelector(state => state.userInfo);
    const currentRole = userPreview?.systemRole;
    const router = useRouter();
    const pathname = usePathname();

    const onboardBlocks = () => {
        const { userAccount, experience, locationPref } = paths.onboarding.jobseeker

        if (typeof reqCollection?.isContactInfoCompleted === "boolean" &&
            !reqCollection?.isContactInfoCompleted) {
            // eslint-disable-next-line no-restricted-globals
            router.push(paths.onboarding.jobseeker.userAccount)
        }
        else if (typeof reqCollection?.isLocationPreferenceCompleted === "boolean" &&
            !reqCollection?.isLocationPreferenceCompleted) {
            // eslint-disable-next-line no-restricted-globals
            router.push(paths.onboarding.jobseeker.locationPref)
        }
        else if (typeof reqCollection?.isWorkHistoryCompleted === "boolean" &&
            !reqCollection?.isWorkHistoryCompleted) {
            // eslint-disable-next-line no-restricted-globals
            router.push(paths.onboarding.jobseeker.experience)

        } else if (reqCollection?.isContactInfoCompleted
            && reqCollection?.isWorkHistoryCompleted
            && reqCollection?.isLocationPreferenceCompleted
            && [userAccount, experience, locationPref].includes(pathname)) {
            router.push(paths.dashboard.jobseekers.profile)
        }
    }

    useEffect(() => {
        let isCalled = false
        if (!isCalled && roles === currentRole) {
            onboardBlocks()
        }
        return () => {
            isCalled = true
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reqCollection])

    return (<>{children}</>)
}
OnboardingGuard.propTypes = {
    roles: PropTypes.string,
    children: PropTypes.node,
};