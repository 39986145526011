import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    faqList: null,
}

const slice = createSlice({
    name: 'FAQs List',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.faqList = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        getFaqListSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.faqList = action.payload;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.faqList = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getFaqListSuccess, } = slice.actions;

export function GetCommonFaqList(path, type) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.StrapiBaseUrl}/${path}?filters[tags][slug][$eq]=${type}`, { headers: { Authorization: `Bearer ${DomainURL.StrapiApiKey}` } });
            dispatch(slice.actions.getFaqListSuccess(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export const faqPath = {
    employers: {
        path: 'faq-employers',
        empVerfication: 'employment-verification',
        contactReq: 'contact-requests'
    },
    jobseekers: {
        path: 'faq-jobseekers',
        empVerfication: 'employment-verification',
        contactReq: 'contact-requests'
    }
}