import { createSlice } from '@reduxjs/toolkit';
// header
import { headerParams } from 'src/utils/headers';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    jobList: null,
    metadata: null,
    queryHolder: null,
    stalePage: null,
    initialQuery: null,
}

const slice = createSlice({
    name: 'InternalJobs',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.jobList = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        jobFetchSuccess(state, action) {
            state.loading = false;
            state.error = null;
            if (action.payload?.data?.data) {
                state.jobList = action.payload?.data?.data;
                state.metadata = action.payload?.data?.metadata;
                state.queryHolder = action.payload?.queryHolder;
                state.stalePage = action.payload?.stalePage;
                state.empty = false
            } else {
                state.empty = action.payload?.data?.data || true
            }
        },
        intialSearch(state, action) {
            state.initialQuery = action.payload
        },
        resetStateFetch(state) {
            state.loading = false;
            state.jobList = null;
            state.metadata = null;
            state.error = null
            state.queryHolder = null;
            state.empty = false;
        },
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { jobFetchSuccess, intialSearch } = slice.actions;

export function getInternalJobsList(token, data, stalePage) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${DomainURL.SearchURLv2}query/job`, { ...data }, { headers: headerParams(token) });
            dispatch(slice.actions.jobFetchSuccess({ data: response.data, queryHolder: { ...data }, stalePage }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetJobsList() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}