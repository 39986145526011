import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    analytics: null,
}

const slice = createSlice({
    name: 'userAnalyticsData',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.analyticss = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        getAnalyticSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.analytics = action.payload;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.analytics = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getAnalyticSuccess, } = slice.actions;

export function GetGeneralAnalytics(token) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}analytics`, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.getAnalyticSuccess(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
