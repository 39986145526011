import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// redux
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'src/redux/store';
// @msal
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

//
import App from './App';

// ----------------------------------------------------------------------
const msalInstance = new PublicClientApplication(msalConfig);
// The MASL js configration initialize with React Application
msalInstance.initialize()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ReduxProvider store={store}>
        <Suspense>
          <App instance={msalInstance} />
        </Suspense>
      </ReduxProvider>
    </BrowserRouter>
  </HelmetProvider>
);
