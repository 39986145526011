// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// routes
// import { paths } from 'src/routes/paths';
// locales
// import { useLocales } from 'src/locales';
// components
// import Label from 'src/components/label';
// Domain-Path
import { DomainURL } from 'src/DomainURL';
// redux-store
import { useSelector } from 'src/redux/store';
// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { userPreview } = useSelector(state => state.userInfo);

  // const { t } = useLocales();

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src={userPreview?.photo ? `${DomainURL.BaseProfileURL}${userPreview.photo}` : ''}
            alt={userPreview?.firstName}
            sx={{ width: 48, height: 48 }}>
            {/* <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Free
          </Label> */}
            {userPreview?.firstName.charAt(0).toUpperCase()}
          </Avatar>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap textTransform="capitalize">
            {userPreview?.firstName.toLowerCase()}{" "} {userPreview?.lastName.toLowerCase()}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {userPreview?.email}
          </Typography>
        </Stack>

        {/* <Button variant="contained" href={paths.minimalUI} target="_blank" rel="noopener">
          {t('upgrade_to_pro')}
        </Button> */}
      </Stack>
    </Stack>
  );
}
