import { createSlice } from '@reduxjs/toolkit';
// header
import { headerParamsSec } from 'src/utils/headers';
// config
import { DomainURL, HMAC } from 'src/DomainURL';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    jobList: null,
    totalpage: null,
    _query: null,

}

const slice = createSlice({
    name: 'externalJobs',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.jobList = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        jobFetchSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.jobList = action.payload?.body;
            state.totalpage = action.payload?.metadata?.TotalRecordCount;
        },
        resetStateFetch(state, action) {
            state.loading = false;
            state.jobList = null;
            state.totalpage = null;
            state.error = null
            state._query = null;
        },
        jobRouter(state, action) {
            state._query = action.payload;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { jobFetchSuccess, jobRouter } = slice.actions;

export function getExtJobsList(values, page) {
    const { headers } = headerParamsSec("/v1/job/filter", HMAC.methods.post)
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${DomainURL.externalJobs}job/filter?&pageNumber=${page}&pageSize=10`, values, { headers });
            dispatch(slice.actions.jobFetchSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetJobsList() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}