
import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    appliedJobsList: null,
    totalpage: null,
}

const slice = createSlice({
    name: 'jsappliedJobsList',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.appliedJobsList = null;
            state.totalpage = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAppliedJobsList(state, action) {
            state.loading = false;
            state.error = null;
            state.appliedJobsList = action.payload?.data;
            state.totalpage = action.payload?.metadata?.TotalRecordCount;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.appliedJobsList = null;
            state.totalpage = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getAppliedJobsList } = slice.actions;

export function GetAppliedJobsList(token, page) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrlVersion2}pipeline/jobs?pageNumber=${page}&pageSize=10`, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.getAppliedJobsList(response?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

