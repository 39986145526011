import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    message: null,
}

const slice = createSlice({
    name: 'userDetailsPut',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.message = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        putUserDetails(state, action) {
            state.loading = false;
            state.error = null;
            state.message = action.payload;
        },
        resetState(state) {
            state.loading = false;
            state.message = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { putUserDetails, resetState } = slice.actions;

export function UpdateUserDetails(token, payload, userId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${DomainURL.BaseUrl}user/${userId}`, { ...payload }, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.putUserDetails(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error.data));
        }
    };
}

export function resetUserPutState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
    }
}