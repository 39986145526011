import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const HEXGONAL_TEXT_COLOR = theme.palette.mode === 'light' ? '#FFFFFF' : '#231f20'
  const HEXGONAL_FILL_COLOR = theme.palette.mode === 'light' ? '#231f20' : "#FFFFFF"

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 140,
        height:50,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

      <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width='100%' height="100%" viewBox="0 0 230.09 46.43">
        <g id="Layer_1-2">
          <text transform="translate(46.68 36)" fill={HEXGONAL_FILL_COLOR} fontFamily="Public Sans,sans-serif;" fontSize="35.73" fontWeight={400}>
            <tspan x="0" y="0" letterSpacing="-.03em">W</tspan>
            <tspan x="29.04" y="0">o</tspan>
            <tspan x="48.66" y="0" letterSpacing="0em">r</tspan>
            <tspan x="60.48" y="0">kcules</tspan></text>
          <polygon points="38.21 33.67 20.11 44.12 2 33.67 2 12.76 20.11 2.31 38.21 12.76 38.21 33.67" fill={HEXGONAL_FILL_COLOR} stroke={HEXGONAL_FILL_COLOR} strokeMiterlimit="10" strokeWidth="4" />
          <polygon points="15.07 34.48 12.09 34.48 6.35 11.95 9.44 11.95 15.07 34.48" fill={HEXGONAL_TEXT_COLOR} />
          <polygon points="27.49 34.48 24.48 34.48 18.7 11.95 21.74 11.95 27.49 34.48" fill={HEXGONAL_TEXT_COLOR} />
          <polygon points="27.61 34.48 25.02 34.48 30.89 11.95 33.86 11.95 27.61 34.48" fill={HEXGONAL_TEXT_COLOR} />
          <polygon points="15.17 34.48 12.58 34.48 18.45 11.95 21.42 11.95 15.17 34.48" fill={HEXGONAL_TEXT_COLOR} />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
