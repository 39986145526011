import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    blobData: null,
    imgData: null
}

const slice = createSlice({
    name: 'blobAuth',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.blobData = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        blobDataFetch(state, action) {
            state.loading = false;
            state.error = null;
            state.blobData = action.payload.res;
            state.imgData = action.payload.file;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.blobData = null;
            state.imgData = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { blobDataFetch, } = slice.actions;

export function BlobAuth(token, drop, type, orgId) {

    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            let response
            if (type === 'USER') {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}utils/media-location?format=${drop?.type?.replace('image/', '')}&type=${type}`, {
                    headers: headerParams(token),
                });
            } else if (orgId) {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}utils/media-location?format=${drop?.type?.replace('image/', '')}&type=${type}&orgId=${orgId}`, {
                    headers: headerParams(token),
                });
            }


            dispatch(slice.actions.blobDataFetch({ res: response?.data?.data, file: drop }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetBlobAuthState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}