import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import axios from '../../../utils/axios';


// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    suggestions: null,
}

const slice = createSlice({
    name: 'userInstituteSearch',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.suggestions = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        fetchInstituteSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.suggestions = action.payload;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.suggestions = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchInstituteSuccess,resetStateFetch } = slice.actions;

export function FetchInstituteSuggestions(query, country, session) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.MapUrlv1}institute?query=${query}&country=${country}&session=${session}`);
            dispatch(slice.actions.fetchInstituteSuccess(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetInstituteState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}