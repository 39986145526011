import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { config, webPushKey } from '../../utils/firebaseConfig';

const firebaseConfig = config.get(process.env.REACT_APP_SERVER)
const vapidKey = await webPushKey.get(process.env.REACT_APP_SERVER)
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const requestFcmToken = async () => Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
        navigator.serviceWorker.register("/firebase-messaging-sw.js").then(registration => getToken(messaging, {
            serviceWorkerRegistration: registration,
            vapidKey
        }).then((currentToken) => localStorage.setItem('deviceToken', currentToken)).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        }));
    }
    throw new Error('Notifcation not granted')
})

export { requestFcmToken,messaging }