import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import AuthModernLayout from 'src/layouts/auth/modern';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { AuthGuard } from 'src/auth/guard';
import { HomePage, mainRoutes } from './main';
import { authRoutes } from './auth-classic';
import { jobseekersRoutes } from './jobseekers';
import { commonRoutes } from './common';
import { onboarding } from './onboarding';

// ----------------------------------------------------------------------

export default function Router() {


  return useRoutes([

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <AuthGuard>
          <AuthModernLayout image='https://dev.workcules.com/asset/media/dev/default/W_AdobeStock_196042217.png'>
            <HomePage />
          </AuthModernLayout>
        </AuthGuard>
      ),
    },
    ...mainRoutes,
    // Auth routes
    ...authRoutes,

    // onboarding
    ...onboarding,

    // Common routes
    ...commonRoutes,

    // Jobseekers routes
    ...jobseekersRoutes,


    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]
  );
}
