import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, OnboardingGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import OnboardCompactLayout from 'src/layouts/onboard';
// components
import { SplashScreen } from 'src/components/loading-screen';
// masl
import { AuthenticatedTemplate } from '@azure/msal-react';
// ----------------------------------------------------------------------

// CLASSIC
const OnboardingExperiencePage = lazy(() => import('src/pages/onboarding/jobseerkers/experience'));
const OnboardingJobseekerContactPage = lazy(() => import('src/pages/onboarding/jobseerkers/jobseeker-contact'));
const OnboardingLocationPrefPage = lazy(() => import('src/pages/onboarding/jobseerkers/locationpref'));

// ----------------------------------------------------------------------

export const onboarding = [
    {
        path: 'onboarding',
        element: (
            <AuthGuard>
                <AuthenticatedTemplate>
                    <RoleBasedGuard hasContent roles={["LAID_OFF_USER"]} sx={{ py: 10 }}>
                        <OnboardCompactLayout>
                            <OnboardingGuard roles='LAID_OFF_USER'>
                                <Suspense fallback={<SplashScreen />}>
                                    <Outlet />
                                </Suspense>
                            </OnboardingGuard>
                        </OnboardCompactLayout>
                    </RoleBasedGuard>
                </AuthenticatedTemplate>
            </AuthGuard>
        ),
        children: [
            {
                path: 'experience',
                element: (<OnboardingExperiencePage />),
            },
            {
                path: 'location-preference',
                element: (<OnboardingLocationPrefPage />),
            },
            {
                path: 'user-account',
                element: (<OnboardingJobseekerContactPage />),
            },

        ],
    },
];

