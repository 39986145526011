import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    message: null,
}

const slice = createSlice({
    name: 'userLogout',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.messages = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        userLogout(state, action) {
            state.loading = false;
            state.error = null;
            state.message = action.payload;
        },
        resetStateFetch(state) {
            state.loading = false;
            state.message = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { userLogout, } = slice.actions;

export function UserGeneralLogout(token, deviceToken) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${DomainURL.BaseUrl}user-device-unregister/${deviceToken}`, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.userLogout(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
