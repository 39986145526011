export const config = new Map()
export const webPushKey = new Map()
// Initialize the Firebase app in the service worker by passing the generated config
config.set('DEV', {
    apiKey: "AIzaSyAooh_0tFpYApPzGuywsNVkyrBQLZjZZfs",
    authDomain: "dev-firebase-385515.firebaseapp.com",
    projectId: "dev-firebase-385515",
    storageBucket: "dev-firebase-385515.appspot.com",
    messagingSenderId: "723914517602",
    appId: "1:723914517602:web:5439bbe7d2ea625b96353b",
    measurementId: "G-PMW43BVHXM"
})
config.set('QA', {
    apiKey: "AIzaSyAooh_0tFpYApPzGuywsNVkyrBQLZjZZfs",
    authDomain: "dev-firebase-385515.firebaseapp.com",
    projectId: "dev-firebase-385515",
    storageBucket: "dev-firebase-385515.appspot.com",
    messagingSenderId: "723914517602",
    appId: "1:723914517602:web:5439bbe7d2ea625b96353b",
    measurementId: "G-PMW43BVHXM"
})

config.set('PRODUCTION', {
    apiKey: "AIzaSyBF1CehT3meCpa5kJrjrCmwXn1bYEsIOk0",
    authDomain: "production-firebase-4facd.firebaseapp.com",
    projectId: "production-firebase-4facd",
    storageBucket: "production-firebase-4facd.appspot.com",
    messagingSenderId: "290975839641",
    appId: "1:290975839641:web:cc8a681d3324beb4260f08",
    measurementId: "G-QC4ZE54ES4"
})


webPushKey.set('DEV', 'BIFZH_ZJvzm-_NReCCxzqKA0XDyyRd05jcTiyNqOausZfYhpN42gxZ-8qlFZfU1cgivocMWX4A6ilxbtw3Z9Ws4')
webPushKey.set('QA', 'BIFZH_ZJvzm-_NReCCxzqKA0XDyyRd05jcTiyNqOausZfYhpN42gxZ-8qlFZfU1cgivocMWX4A6ilxbtw3Z9Ws4')
webPushKey.set('PRODUCTION', 'BONe9-EqXeBRoonhG3bQhl63lBYB40iZro7o4R8ygRYK6jj1YglPxWLOwca97ExtbVoz-qBsqpJAymAydtHhQ2s')

 