import PropTypes from 'prop-types';
// store
import { useSelector } from 'src/redux/store';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, sx }) {
  // Logic here to get current user role
  const { userPreview, loading } = useSelector(state => state.userInfo);
  // const currentRole = 'user';
  const currentRole = userPreview?.systemRole; // admin;

  if (roles.includes(currentRole)) {
    return <> {children} </>;
  }
  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    if (window && !loading && hasContent) {
      window.location.assign('/404')
    }
    return loading ? <SplashScreen /> : null
  }


  return <> {children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
