import { combineReducers } from 'redux';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// ============ Common for all kind of users ===============
// B2C token Slice
import authRedcuer from './slices/common/auth';
// ISO Code Slice
import isoRedcuer from './slices/common/iso-code';
// User Details Get Slice
import userDetailRedcuer from './slices/common/user-details';
// User Status Req Get Slice
import updateUserReducer from './slices/common/user-update';
// Common Analytics Get Slice
import generalAnalyticsReducer from './slices/common/analytic';
// Blob auth token get
import blobAuthReducer from './slices/common/blob-auth';
// Upload User Profile Image
import userProfileImgUploadReducers from './slices/common/user-image-upload';
// User mobile OTP Request
import OtpRequestReducers from './slices/common/otp-request'
// User mobile OTP verification
import OtpVerificationReducers from './slices/common/otp-verify'
// User Logout
import UserLogoutReducers from './slices/common/logout'
// FAQs
import FaqListReducer from './slices/common/faqs-common'
// Notification Alert
import NotificationAlertReducers from './slices/common/notification-alert'
// Notification Alert View All
import NotificationViewAllReducers from './slices/common/notification-view-all'
// inapp notification patch
import NotificationPatchReducers from './slices/common/inapp-patch'
// Address Search
import FetchAddressSuggestionsReducers from './slices/common/address-search'
// City Search
import FetchCitySuggestionsReducers from './slices/common/city-search'
// Institution Search
import FetchInstituteSuggestionsReducers from './slices/common/institute-search'

// ============== Jobseekers reducers ==================
// jobseekers Onboard
import jsSignUpReducers from './slices/onboarding/jobseekers/jobseekers-signup'
// Jobseekers Profile Status Get Slice
import jsProfileStatusReducer from './slices/job-seekers/jobseeker-status';
// Jobseekers Profile Details Get Slice
import jsProfileDetailsReducer from './slices/job-seekers/jobseeker-profile';
// Jobseekers Location Preference Get Slice
import jsLocationPreferenceReducer from './slices/job-seekers/location-preference/location-individual';
// Jobseekers Location Preference Put Slice
import jsLocationPreferenceUpdateReducer from './slices/job-seekers/location-preference/location-update';
// Jobseekers Individual Experience Get Slice
import jsIndivExperienceReducer from './slices/job-seekers/experience/experience-individual';
// Jobseekers Website Lookup Get Slice
import jsSiteLookupReducer from './slices/job-seekers/experience/site-lookup';
// Jobseekers Experience Add&Update Put Slice
import jsExperienceNewUpdateReducer from './slices/job-seekers/experience/experience-put';
// Jobseekers Experience Delete Slice
import jsExperienceDeleteReducer from './slices/job-seekers/experience/experience-delete';
// Jobseekers Individual Education Get Slice
import jsIndivEducationReducer from './slices/job-seekers/education/education-individual';
// Jobseekers Education Categories Get Slice
import jsEducationCategoriesReducer from './slices/job-seekers/education/education-categories';
// Jobseekers Education Add&Update Put Slice
import jsEducationNewUpdateReducer from './slices/job-seekers/education/education-put';
// Jobseekers Education Delete Slice
import jsEducationDeleteReducer from './slices/job-seekers/education/education-delete';
// Jobseekers Individual Certification Get Slice
import jsIndivCertificationReducer from './slices/job-seekers/certification/certification-individual';
// Jobseekers Certification Add&Update Put Slice
import jsCertificationNewUpdateReducer from './slices/job-seekers/certification/certification-put';
// Jobseekers Certification Delete Slice
import jsCertificationDeleteReducer from './slices/job-seekers/certification/certification-delete';
// Jobseekers Employment List Get Slice
import jsEmploymentListReducer from './slices/job-seekers/employment/employment-list';
import jsEvInitReducer from './slices/job-seekers/employment/initiate-ev';
import jsEvAcceptReducer from './slices/job-seekers/employment/accept-ev';
import jsEvIndiDetailsReducer from './slices/job-seekers/employment/view-ev-details';
// Jobseekers Contact Request List Get Slice
import jsContactReqListReducer from './slices/job-seekers/contact-request/get-contact-request';
import jsContactReqResponceReducer from './slices/job-seekers/contact-request/put-contact-request';
// Jobseekers External Job List Get Slice
import jsExtJobsListReducer from './slices/job-seekers/jobs/external/jobs-list';
import jsExtJobsPreviewReducer from './slices/job-seekers/jobs/external/jobs-individual';

// Jobseeker Internal Jobs Slice
import jsInternalJobsReducers from './slices/job-seekers/jobs/internal/jobs-list'
import jsInternalJobsFilters from './slices/job-seekers/jobs/internal/jobs-filters'
import jsInternalJobsPreviewReducer from './slices/job-seekers/jobs/internal/jobs-individual'
import jsInternalJobsFavouriteReducer from './slices/job-seekers/jobs/internal/jobs-favourite'
import jsInternalJobsFavDetailsReducer from './slices/job-seekers/jobs/internal/jobs-favourite-details'
import jsInternalJobsApplyReducer from './slices/job-seekers/jobs/internal/jobs-apply'
// jobseeker My Jobs
import jsAppliedJobsReducer from './slices/job-seekers/jobs/internal/myjobs/applied-jobs'
import jsSavedJobsReducer from './slices/job-seekers/jobs/internal/myjobs/saved-jobs'
// jobseeker matrics
import contactRequestMatricsReducers from './slices/common/contact-request-matrics'
import employementVerificationMatricsReducers from './slices/common/employment-verification-matrics'

// ----------------------------------------------------------------------
export const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});
export const storage =
  typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();
export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
const rootReducer = combineReducers({
  // ============ Common for all kind of user users ===============
  auth: authRedcuer, // -> For B2C token
  iso: isoRedcuer, // -> For ISO Codes
  notificationAlert: NotificationAlertReducers, //  -> For Alert
  notificationViewAll: NotificationViewAllReducers,
  jsSignup: jsSignUpReducers, // -> For Jobseeker Signup
  userInfo: userDetailRedcuer, // -> For User Details
  userUpdate: updateUserReducer, // -> For Update User Details
  location: FetchAddressSuggestionsReducers, // -> For User search address
  cities: FetchCitySuggestionsReducers, // -> For User search city
  institute: FetchInstituteSuggestionsReducers, // -> For User search Institution
  generalAnalytics: generalAnalyticsReducer, // -> For blobAuth Details
  userBlob: blobAuthReducer, // -> For blobAuth Details
  userImgUpload: userProfileImgUploadReducers, // -> For blobAuth Details
  userMobOTPVerify: OtpVerificationReducers, // -> For User Mobile OTP
  userMobOTPReq: OtpRequestReducers, // -> For User Request OTP to Mobile Verification
  userLogout: UserLogoutReducers, // -> For User Logout
  userFaqList: FaqListReducer, // -> For FQAs
  notifyPatch: NotificationPatchReducers, // -> For Notification patch
  crMatrics: contactRequestMatricsReducers,
  evMatrics: employementVerificationMatricsReducers,

  // ============== Jobseekers reducers ==================
  profileStatus: jsProfileStatusReducer, // -> For User Profile Status
  jsProfile: jsProfileDetailsReducer, // -> For Jobseeker Profile Details
  jsLocation: jsLocationPreferenceReducer, // -> For Jobseeker Get Location Preference
  jsLocationUpdate: jsLocationPreferenceUpdateReducer, // -> For Jobseeker Location Preference Update
  jsIndivExp: jsIndivExperienceReducer, // -> For Jobseekers Individual Experience Get
  jsSiteLookup: jsSiteLookupReducer, // -> For Jobseekers Site Lookup Get
  jsPutExperience: jsExperienceNewUpdateReducer, // -> For Jobseekers Experience Add & Update Put
  jsDelExperience: jsExperienceDeleteReducer, // -> For Jobseekers Experience Delete
  jsIndivEdu: jsIndivEducationReducer, // -> For Jobseekers Individual Education Get
  jsEduCategories: jsEducationCategoriesReducer, // -> For Jobseekers Education Categories Get
  jsPutEducation: jsEducationNewUpdateReducer,// -> For Jobseekers Education Add & Update Put
  jsDelEducation: jsEducationDeleteReducer,// -> For Jobseekers Education Delete
  jsIndivCert: jsIndivCertificationReducer, // -> For Jobseekers Individual Certification Get
  jsPutCertification: jsCertificationNewUpdateReducer,// -> For Jobseekers Certification Add & Update Put
  jsDelCertification: jsCertificationDeleteReducer,// -> For Jobseekers Certification Delete
  jsEvList: jsEmploymentListReducer, // -> For Jobseeker Employment List
  jsInitEv: jsEvInitReducer, // -> For Jobseeker Employment List
  jsAcceptEv: jsEvAcceptReducer, // -> For Jobseeker Employment List
  jsIndiEv: jsEvIndiDetailsReducer, // -> For Jobseeker Employment List
  jsCrList: jsContactReqListReducer, // -> For Jobseeker Contact Request List
  jsCrtReqResponce: jsContactReqResponceReducer, // -> For Jobseeker Contact Request Responce

  jsIntrJobList: jsInternalJobsReducers, // -> For Jobseeker Internal Job List
  jsIntrJobFilters: jsInternalJobsFilters, // -> For Jobseeker Internal Job Filters
  jsIntrJobPreview: jsInternalJobsPreviewReducer, // -> For Jobseeker Internal Job Preview
  jsIntrJobFav: jsInternalJobsFavouriteReducer, // -> For Jobseeker Internal Job Favourite
  jsIntrJobFavDetails: jsInternalJobsFavDetailsReducer, // -> For Jobseeker Internal Job Fav Details
  jsIntrJobApply: jsInternalJobsApplyReducer, // -> For Jobseeker Internal Job Apply

  jsAppliedJobsList: jsAppliedJobsReducer, // -> For Jobseeker Applied  Internal Job list
  jsSavedJobsList: jsSavedJobsReducer, // -> For Jobseeker saved  Internal Job list

  jsExtJobList: jsExtJobsListReducer, // -> For Jobseeker External Job List
  jsExtJobPreview: jsExtJobsPreviewReducer, // -> For Jobseeker External Job Preview

});
export default rootReducer;
