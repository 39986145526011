// B2C login configuration

const redirectUri = process.env.REACT_APP_BASE_URL;
const postLogoutRedirectUri = process.env.REACT_APP_LOGOUT_REDIRECT_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = process.env.REACT_APP_AUTHORITY_ID;
const authorityDomain = process.env.REACT_APP_AUTHORITY_DOMAIN;

const DomainURL = {
  HostURL: process.env.REACT_APP_BASE_URL,
  BaseUrl: process.env.REACT_APP_USER_SERVICE_URL_VERSION1,
  StrapiBaseUrl: process.env.REACT_APP_STRAPI_API,
  StrapiApiKey: process.env.REACT_APP_STRAPI_KEY,
  MapUrlv1: process.env.REACT_APP_MAPS_URL_VERSION1,
  BaseUrlVersion2: process.env.REACT_APP_USER_SERVICE_URL_VERSION2,
  BaseExternalURL: process.env.REACT_APP_EXTERNAL_SERVICE_URL_VERSION,
  BaseProfileURL: process.env.REACT_APP_PROFILE_URL,
  BaseProfileUserURL: process.env.REACT_APP_DEFAULT_USER_PROFILE_URL,
  BaseProfileOrgURL: process.env.REACT_APP_DEFAULT_ORG_PROFILE_URL,
  UserSearchURL: process.env.REACT_APP_USERSEARCH_URL_VERSION1,
  UserSearchURLV2: process.env.REACT_APP_USERSEARCH_URL_VERSION2,
  SearchURLv2: process.env.REACT_APP_USERSEARCH_URL_VERSION2,
  redirectPathName: process.env.REACT_APP_REDIRECT_PATHNAME,
  gMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  blobName: process.env.REACT_APP_BLOB_ACCOUNT_NAME,
  externalJobs: process.env.REACT_APP_EXTERNAL_JOB_SERVICE_URL_VERSION,
  blobMediaDir: process.env.REACT_APP_MEDIA,
  authority,
  authorityDomain,
  clientId,
  redirectUri,
  postLogoutRedirectUri,
};

export const HMAC = {
  methods: {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE'
  },

}

export { DomainURL };
