import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// redux-store
import { useSelector } from 'src/redux/store';
// Domain-Path
import { DomainURL } from 'src/DomainURL';
// @azure
import { useMsal } from '@azure/msal-react';

// ----------------------------------------------------------------------

export default function AccountPopoverOnboard() {
    const router = useRouter();
    const { userPreview } = useSelector(state => state.userInfo);
    const { instance } = useMsal();

    const { enqueueSnackbar } = useSnackbar();

    const popover = usePopover();

    const handleLogout = async () => {
        try {
            popover.onClose();
            await instance.logout()
            await localStorage.clear()
            router.replace('/');

        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }
    };



    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(popover.open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <Avatar
                    src={userPreview?.photo ? `${DomainURL.BaseProfileURL}${userPreview.photo}` : ''}
                    alt={userPreview?.firstName}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                >
                    {userPreview?.firstName.charAt(0).toUpperCase()}
                </Avatar>
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
                <Box sx={{ p: 2, pb: 1.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {userPreview?.firstName.concat(" ", userPreview?.lastName)}
                    </Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {userPreview?.email}
                    </Typography>
                </Box>


                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={handleLogout}
                    sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
                >
                    Logout
                </MenuItem>
            </CustomPopover>
        </>
    );
}
