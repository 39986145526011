import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OnBoarding

// Sign Up
const JobseekerSignUp = lazy(() => import('src/pages/onboarding/jobseerkers/sign-up'));

// ----------------------------------------------------------------------

const authAmplify = {
  path: 'jobseeker',
  element: (
    // <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    // </GuestGuard>
  ),
  children: [
    {
      path: 'signup',
      element: (
        <AuthClassicLayout>
          <JobseekerSignUp />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: '/',
    children: [authAmplify],
  },
];
